import React from "react";
import { Link } from "react-router-dom";
import "./Training.css";

function Training() {
  return (
    <div id="training">
      <div className="container">
        <div className="row text-center">
          <h4 className="title-top">Learn Carpet Dyeing the Easy Way! </h4>
          <h3 className="text-dyebold">WE ALSO GIVE CARPET DYEING TRAINING</h3>
          <h4 className="subtitle">
            Dyebold Is Florida’s Leading Carpet & Rug Dyeing Company. We Offer
            Complete Solutions For Your Carpet Dyeing Problems.
          </h4>
        </div>
        <div className="space-gap"></div>
        <div className="row">
          <div className="col-md-6">
            <p>
              We are also a &nbsp;
              <strong>
                <Link to="https://dyebold.company.site/Trainings-c109028504">
                  carpet training institute
                </Link>
              </strong>{" "}
              &nbsp; and proud in teaching this highly specialized & unique
              skill of carpet dyeing through our online and in-person sessions.
              Our Studio is located in Tampa Bay, FL, where we receive carpets &
              rugs from throughout the US and abroad and we dye, repair and
              restore them in front of our students, guiding them throughout the
              whole process, explaining each and every detail and answering
              their questions.
              <br />
              <br />
              <br />
              If you are also interested in learning more about our carpet & rug
              dyeing classes click the link below.
            </p>
          </div>

          <div className="col-md-6">
            <img
              src="images/training.jpg"
              className="img-fluid img-border"
              alt="Responsive image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Training;
