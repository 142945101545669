import React from "react";
import "./About.css";
import "../App.css";

export default function About() {
  return (
    <div id="about">
      <div id="page-top-bg">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="page-title">
                <h2>ABOUT DYEBOLD</h2>
                <p>
                  TRAINED BY THE INDUSTRY LEADER IN COLOR RESTORATION SERVICES.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="black-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="left">FLORIDA LEADING CARPET DYEING COMPANY</div>
            </div>
            <div className="col-md-6">
              <div className="right">
                We are quite dedicated to giving our facilities in carpet and
                rug color restoration.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gray-bg">
        <div className="space-gap"></div>
        <div className="container">
          <div className="row text-center">
            <h4 className="title-top">
              OUR TECHNIQUES MAKE US DISTINCTIVE IN THE MARKET.{" "}
            </h4>
            <h3 className="text-dyebold">
              EXPERIENCED AND PROFESSIONAL CARPET DYEING COMPANY
            </h3>
          </div>
          <div className="space-gap"></div>
          <div className="row">
            <div className="col-md-6">
              <img
                src="images/carpet-illustration.jpg"
                className="img-fluid"
                alt="Responsive image"
              />
            </div>

            <div className="col-md-6">
              <p>
                DyeBold has a highly trained, experienced and specialized team
                of carpet colorists available to serve you with quality carpet
                dyeing services. We are included in the top carpet and rug
                dyeing businesses based in Florida. However, we also take orders
                from the whole US and do some overseas projects. Issa Hoker is
                the founder and CEO of DyeBold and is known as a master colorist
                in the industry. She is an experienced and famous color artist
                in the market. Our professionals are trained by the top dyeing
                artists in the industry. Therefore, you can rely on our
                services. DyeBold believes in delivering excellent work that
                satisfies customers. We have not only satisfied but also amazed
                many clients with our professionalism and artistic carpet dyeing
                and rug restoration skills.
              </p>
            </div>
            <div className="space-gap"></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="space-gap"></div>
          <div className="col-md-12">
            <h3>OUR PORTFOLIO</h3>
            <div className="section-description">
              We are certified, licensed as well as insured carpet dyers. With
              years of experience in the industry, we have served many clients.
              Some of the most popular among them include: Wyndham Hotel @
              Clearwater Beach – FL, Four Seasons Hotel @ Miami Beach – FL,
              Aloft of Santa Clara – CA, Hotel Nikko @ San Francisco – CA,
              University Club of Washington D.C. in Washington D.C. Our
              five-star rating on Google My Business and Yelp illustrate the
              satisfaction of our clients with our work. We specialize in carpet
              dyeing, carpet side match, and rug restoration. You can also see
              our gallery to observe the quality of work we deliver to our
              clients.
            </div>
          </div>
          <div className="space-gap"></div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h3>WHY CHOOSE DYEBOLD?</h3>
            <div className="section-description">
              DyeBold is an excellent choice for you if you want a professional
              carpet dyeing and rug restoration service. One of the most
              important benefits you enjoy by hiring us is the magnificent
              quality of work. We do not compromise on it as we want to please
              our clients with our services. Our professionals also provide easy
              solutions to your carpet dyeing problems. Being experienced in the
              industry, we can take care of the projects without any discomfort.
              We also add some creativity to our work that leaves a good
              impression on our customers.
              <br />
              <br />
              <strong>
                We have some of the top color artists in the industry ready to
                serve you with the best.
              </strong>
            </div>
          </div>
          <div className="space-gap"></div>
        </div>
      </div>

      <div className="gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6" id="award-img">
              <img
                src="images/award.jpg"
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
            <div className="col-md-6">
              <div className="section-description">
                <h3>OUR CERTIFICATIONS</h3>
                Certifications illustrate the credibility of a service provider.
                The more certifications, the more the eligible worker will be
                doing their task. As a service provider, we have numerous
                certifications that show how trained we are in doing the job we
                are doing. <br />
                <br />
                Our certifications include Colorful Carpets Certification (Dye
                Master Chris Howell), Color Repair Technician CRT IICRC, Carpet
                Repair, and Reinstallation Technician RRT, Master Dyer Course
                (By Dye Master Ron Toney), WoolSafe® Fiber Care Specialist
                course, RRT Carpet Repair and Installation Technician IICRC, Rug
                Seminar & Rug ID Certification, and Carpet Cleaner Technician
                IICRC.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dyebold-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="section-description">
                <h3>DYEBOLD ACADEMY</h3>
                DyeBold has started a training program for people who take
                interest in learning the artistic skill of dyeing carpets and
                restoring rugs. We aim to teach excellent skills to our students
                so that when they work in the industry, they do a profitable
                business. For the people who take interest in this work but find
                it difficult, we devise easy dyeing techniques that can help
                them. Trainers we assign to teach you are also specialized and
                experienced color artists in the industry. <br />
                <br />
                Their experience is not only beneficial for your learning but
                also helps you avoid the mistakes that people mostly make. If
                you are interested in learning this splendid skill and achieving
                excellence in it. Click on the link to get more information.
              </div>
            </div>

            <div className="col-md-6" id="issa-trainning">
              <img
                src="images/isas-teaching-students.jpg"
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
