import React from "react";
import { Link } from "react-router-dom";
import "./Aboutcompany.css";

function Aboutcompany() {
  return (
    <>
      <div id="cards" className="container">
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="card black">
              <div className="card-body">
                <h4 className="card-title text-white">Carpet Dyeing</h4>
                <p className="card-text">
                  Experienced carpet dyeing company provides you with amazing
                  carpet dyeing solutions. Take advantage of remarkable color
                  quality and make your carpets look glorious.
                </p>
                <Link to="/carpet-dyeing" className="card-link">
                  Click here
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="card dyebold">
              <div className="card-body">
                <h4 className="card-title">Dyebold Academy</h4>
                <p className="card-text">
                  Learn Carpet Dyeing at a fraction of the time, with no more
                  guessing! So that you can enter this profitable elite market
                  and make more money!
                </p>
                <a
                  href="https://dyebold.company.site/"
                  target="_blank"
                  className="card-link"
                >
                  Click here
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4">
            <div className="card black">
              <div className="card-body">
                <h4 className="card-title text-white">Contact Dyebold</h4>
                <p className="card-text">
                  Call us or book an appointment if you are interested in
                  getting your carpet or rug dyed by highly trained
                  professionals in the industry.
                </p>
                <Link to="/contact-us" className="card-link">
                  Click here
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="aboutarea">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <p className="about">
                The team of DyeBold goes through a simple yet highly efficient
                method while dyeing a carpet. As a reputable carpet dyeing
                company, we maintain the quality of our work by focusing on the
                tiny details of the dyeing process. The first important step we
                follow is neutralizing the bleach in the carpet. For this
                purpose, our professionals heat the neutralizing agent and apply
                it deep into the rug and carpet. After this, we gather the right
                tools that include dyes, a syringe, and an airbrushing system.
                Color analysis is also a highly important step that we do not
                miss. The hue of dye we select decides the new color of your
                carpet.
                <br />
                <br />
                The final color is achieved by mixing appropriate dye formulas.
                Once the dye for the rug or carpet is ready, we apply it to your
                carpet or rug. The termination step is detailing and finishing
                the carpet that depicts the final and magnificent result of our
                work.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aboutcompany;
