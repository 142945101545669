import React from "react";
import "../App.css";

import "../components/HeroSection.css";
import "../components/SocialIcon.css";
import "../components/Aboutcompany.css";
import "../components/Company";
import "../components/Professional";
import "../components/Certifications";
import "../components/Service";
import "../components/BestChoice";
import "../components/Training";

import HeroSection from "../components/HeroSection";
import SocialIcon from "../components/SocialIcon";
import Aboutcompany from "../components/Aboutcompany";
import Company from "../components/Company";
import Professional from "../components/Professional";
import Certifications from "../components/Certifications";
import Service from "../components/Service";
import BestChoice from "../components/BestChoice";
import Training from "../components/Training";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <HeroSection />
      <SocialIcon />
      <Aboutcompany />
      <Company />
      <Professional />
      <Certifications />
      <Service />
      <BestChoice />
      <Training />
    </>
  );
}
export default Home;
