import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-container">
      <h4>FLORIDA'S LEADING CARPET DYEING COMPANY</h4>
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2 className="footer-subtitle">Business Hours</h2>
            <h5 className="day">
              Mon-Friday: <br />
              24 Hours
            </h5>
            <h2 className="footer-subtitle">Call us Now:</h2>
            <h5>+1 (813) 320 4000</h5>

            <h2 className="footer-subtitle">Location:</h2>
            <h5>Florida United States.</h5>
          </div>
          <div className="footer-link-items">
            <h2>Services</h2>
            <a href="https://dyebold.app/" target="_blank">
              Trainning
            </a>
            <a href="https://dyebold.company.site/" target="_blank">
              Carpet Dyebing
            </a>
            <a href="https://dyebold.company.site/" target="_blank">
              Carpet Side Match
            </a>
            <a href="https://dyebold.company.site/" target="_blank">
              Specialty Rug Restoration
            </a>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Pages</h2>
            <Link to="/">Home</Link>
            <Link to="about-us">About Us</Link>
            <a href="https://dyebold.company.site/" target="_blank">
              Store
            </a>
            <Link to="/our-gallery">Gallery</Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>
          <div className="footer-link-items">
            <h2>Social Media</h2>
            <a href="https://www.facebook.com/dyebold/" target="_blank">
              Facebook
            </a>
            <a href="https://www.youtube.com/c/DyeBold/videos" target="_blank">
              Youtube
            </a>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/">
              <img
                className="top img-fluid"
                src="images/dyebold-logo.jpg"
                alt=""
              />
            </Link>
          </div>
          <small className="website-rights">
            COPYRIGHT 2023 © DYEBOLD. ALL RIGHTS RESERVED.
          </small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
