import React from "react";
import "./Company.css";

function Company() {
  return (
    <div id="company">
      <div className="overlay">
        <div className="container">
          <div className="padding">
            <div className="row text-center ">
              <h4 className="title-top">AT DYEBOLD WE ARE 100% DEDICATED. </h4>
              <h3 className="text-dyebold">
                LICENSED, CERTIFIED AND WELL TRAINED:
              </h3>
              <h4 className="company-subtitle">CARPET DYEING COMPANY.</h4>
            </div>
            <div className="space-gap"></div>
            <div className="row">
              <div className="col-md-6">
                <img
                  className="img-border img-fluid"
                  src="images/company.jpg"
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <p className="desc">
                  DyeBold is a licensed and certified carpet dyeing company
                  always ready to serve you with premium quality carpet dyeing
                  and restoration facilities.
                  <br /> <br />
                  We do not only take the responsibility of dyeing your carpets
                  and rugs. You can also contact us if you want restoration and
                  side match services from our experienced professionals.
                  Customer satisfaction is the biggest achievement of our
                  company.
                  <br /> <br />
                  <strong>
                    By providing great quality dyeing and color restoration
                    services, we have pleased many clients.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
