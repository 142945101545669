import React from "react";
import "./Contact.css";
import "../App.css";

export default function Contact() {
  return (
    <>
      <div id="contact">
        <div id="page-top-bg">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="page-title">
                  <h2>CONTACT US</h2>
                  <p>
                    TRAINED BY THE INDUSTRY LEADER IN COLOR RESTORATION
                    SERVICES.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="space-gap"></div>
        <div className="row">
          <h3 className="margin-t-top">
            FLORIDA'S LEADING CARPET & RUG DYEING COMPANY
          </h3>
          <h2>CONTACT DYEBOLD:</h2>
          <div className="space-gap"></div>
          <div className="col-md-6 bg-black text-center">
            <i aria-hidden="true" className="fas fa-phone-square-alt"></i>
            <a href="tel:+18133204000">CALL US NOW: </a>
            <p>
              <a href="tel:+18133204000">+1 (813) 320 4000</a>
            </p>
          </div>

          <div className="col-md-6 bg-dyebold text-center">
            <i aria-hidden="true" className="fas fa-envelope-open"></i>
            <a href="tel:+18133204000">DYEBOLDSERVICES@GMAIL.COM </a>
            <p>
              <a href="tel:+18133204000">EMAIL US NOW</a>
            </p>
          </div>
        </div>
        <div className="space-gap"></div>
      </div>
    </>
  );
}
