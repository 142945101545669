import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Navbar.css";

function Navigation() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary bg-light">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            className="top img-fluid dyebold-logo"
            src="images/dyebold-logo-1.png"
            alt="Dyebold LLC Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link href="https://dyebold.company.site/" target="_blank">
              Store
            </Nav.Link>
            <Nav.Link as={Link} to="/about-us">
              About Us
            </Nav.Link>
            <Nav.Link as={Link} to="/academy">
              Dyebold Academy
            </Nav.Link>
            <Nav.Link as={Link} to="/carpet-dyeing">
              Carpet Dyeing
            </Nav.Link>
            <Nav.Link as={Link} to="/carpet-side-match">
              Carpet Side Match
            </Nav.Link>
            <Nav.Link as={Link} to="/specialty-rug-restoration">
              Specialty Rug Restoration
            </Nav.Link>
            <Nav.Link as={Link} to="/our-gallery">
              Our Gallery
            </Nav.Link>
            <Nav.Link as={Link} to="/contact-us">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
