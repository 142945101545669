import React from "react";
import { Link } from "react-router-dom";
import "./Professional.css";

function Professional() {
  return (
    <div id="professional">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <img
              className="img-border img-fluid"
              src="images/professional.jpg"
              alt=""
            />
          </div>
          <div className="col-sm-12 col-md-8">
            <div className="padding">
              <h3 className="text-dyebold">
                PROFESSIONAL CARPET & RUG DYEING SERVICES
              </h3>
              <p>
                Searching for a professional service provider for your rug &
                &nbsp;
                <strong>
                  <Link to="/carpet-dyeing">carpet dyeing?</Link>
                </strong>
                &nbsp; DyeBold is always there to serve you with the best.
                Because of our professionalism our clients always get satisfied
                with our services. We have extensive experience in dyeing and
                restoring carpets & rugs of all types, colors, and patterns.
                <br />
                <br />
                Rugs mostly act as a fascinating decors and are mostly placed at
                the sites where they attract visitors like the living room.
                Therefore, the designs and colors of your rug must be unique and
                catchy. DyeBold is a well-trained carpet dyeing company that
                dyes the rug well so that it catches the eye of viewers and
                leaves a lasting impression on them. We also adopt an artistic
                approach while dyeing your carpet. The quality of color we use
                mostly provides you with long-lasting benefits.
                <br />
                <br />
                <strong className="">
                  Call us or book an appointment if you are interested in
                  getting your carpet or rug dyed by highly trained
                  professionals in the industry.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Professional;
