import React from "react";
import "./Dyeing.css";
import "../App.css";

export default function Dyeing() {
  return (
    <div id="dyeing">
      <div id="page-top-bg">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="page-title">
                <h2>CARPET DYEING</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="margin-t-top">
              FLORIDA'S TOP RATED CARPET DYEING SERVICE
            </h3>
            <p>
              Carpet dyeing is a unique art that many people find fascinating.
              By using this artistic skill, professionals apply color to your
              carpet and make it vibrant. An appropriate carpet dyeing technique
              is also used to change the color of the carpet. It involves mixing
              a dye and applying it over a scrubbed or new carpet. After getting
              dried, a new color becomes visible on the rug or carpet. DyeBold
              is one of the top carpet dyeing companies based in Florida. We are
              considered to be excellent service providers due to our
              professionalism and good quality work
            </p>
            <p>
              We dye your carpet creatively so that it leaves a lasting
              impression on viewers. We use high-quality colors that maintain
              the glory of your carpet for a long duration.
            </p>

            <h3 className="margin-t-top">
              BENEFITS OF CARPET DYEING WITH DYEBOLD
            </h3>
            <p>
              DyeBold is a reputable carpet dyeing firm in the market due to the
              amazing benefits we offer to our clients. We make unique designs
              with the help of innovative carpet dyeing techniques that make
              your carpets look impressive. If the color fades or it gets some
              stain, we offer dyeing which is an efficient option to restore it.
              We also make your carpet look like new as the colors we use are of
              excellent quality. Moreover, we can also restore the original
              color of your carpet.
            </p>
            <strong>
              Here, we have discussed some facilities that make us distinctive
              in the carpet dyeing industry.
            </strong>

            <h3 className="margin-t-top">CARPETS GET STAINED AND FADED</h3>

            <p>
              Carpets are significantly vibrant and attractive when they are
              installed in a place, however, their charm goes away with time. If
              the color quality of the carpet is not good, it may fade within no
              time.
            </p>
            <p>
              Stains are also included in the major issues of the carpets. All
              these problems can be eliminated with the help of a professional
              carpet dyeing process, which restores the original color of the
              carpets. DyeBold is always ready to help you with this. We
              understand all the problems of our clients and dye their carpets
              suitably.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <img
                src="images/carpet-colors.jpg"
                className="img-fluid img-border"
                alt="Responsive image"
              />
            </div>
            <div className="col-md-6">
              <img
                src="images/carpet-wash.jpg"
                className="img-fluid img-border"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="black-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="black-sec-title margin-t-top">
                CARPET DYEING RESTORES THE ORIGINAL COLOR
              </h3>
              <p>
                Carpet dyeing not only gives a distinctive color to a new carpet
                but also can restore the old one. Restoring the original color
                is not an easy task. DyeBold can help you in this matter, as we
                have experienced and trained professionals to do this task. We
                do not only use a good technique for this purpose but also
                select appropriate colors. The dyes provided by the
                manufacturers are considered to be best in this matter. DyeBold
                dyes carpets by using high-quality hues so that we can restore
                the original color. With the help of a combination of stain
                removal and dye replacement, we can easily restore many carpets
                that have been stained due to any reason.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="margin-t-top">WHY DYEBOLD?</h3>
            <p>
              DyeBold specializes in dyeing your carpets appropriately. We have
              a team of the best dyers who are popular in the industry. Our
              professionals do a fine job that is always liked by the people. We
              also make artistic designs on your carpets with our unique dyeing
              skills that catch the attention of people. We also restore the
              faded or faulty hue of your carpet suitably so that it looks new
              and glorious.
            </p>

            <h3 className="margin-t-top">DYEBOLD ACADEMY</h3>
            <img
              src="images/dyebold-academy-studio.jpg"
              className="img-fluid"
              alt="Responsive image"
            />
            <p className="margin-t-top">
              Carpet dyeing is an exclusive skill that can be profitable if you
              learn it with dedication.
            </p>
            <p>
              For your ease, DyeBold Academy has started a learning program for
              interested students. You can learn amazing skills from
              professionals in the industry. The experience you get by learning
              from our facilitators can be highly advantageous for you when you
              start working on the projects of your clients.
            </p>
            <p>
              Feel free to contact us if you want to benefit from our training
              programs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
