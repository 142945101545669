import React from "react";
import "./RugRestoration.css";
import "../App.css";

export default function RugRestoration() {
  return (
    <div id="rugrestoration">
      <div id="page-top-bg">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="page-title">
                <h2>RUG RESTORATION</h2>
                <p>
                  TRAINED BY THE INDUSTRY LEADER IN COLOR RESTORATION SERVICES.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h5 className="margin-t-top">
              SEARCHING FOR AN EXCELLENT SERVICE PROVIDER FOR YOUR RUG
              RESTORATION?
            </h5>
            <h3 className="margin-t-top">SPECIALTY RUG RESTORATION</h3>
            <p>
              DyeBold is always there to serve you with the best rug restoration
              services. We are highly experienced in restoring many types of
              rugs. Our professionals can do this task without worrying about
              the pattern and color of the rug.
            </p>

            <p>
              We have a studio in Florida, where we perform the restoration
              process. Rugs come to this place from Multiple places in the US
              and even some overseas areas. However, if you find difficulty in
              removing rugs from their place, our colorists can come to your
              location and restore your rug at the site. DyeBold has dealt with
              numerous clients from various parts of the world and offered
              satisfactory services to them. We understand that a rug is an
              exceptional fabricated item that can be used for multiple
              purposes. You can place it on that part of the floor where it
              enhances the glory of your room. Therefore, we make it tempting
              for our clients with our specialized restoration skills.
            </p>

            <p>
              When it gets stained or damaged due to any reason, the grace of
              its appearance is compromised. As highly specialized rug
              restoration services, we provide our clients with efficient and
              advantageous solutions to these problems. The faults in your rug
              can be eliminated by our professionals because they are
              experienced in performing such tasks. Feel free to contact us to
              get excellent rug repairing facilities at market competitive
              rates.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <img
                src="images/dyebold-carpet-repair.jpg"
                className="img-fluid img-border"
                alt="Responsive image"
              />
            </div>
            <div className="col-md-6">
              <img
                src="images/dyebold-rug-repair.jpg"
                className="img-fluid img-border"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="black-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="black-sec-title margin-t-top">
                WHY DYEBOLD FOR RUG RESTORATION?
              </h3>
              <p>
                DyeBold serves you by using an easy yet efficient rug
                restoration method that makes your rug look new and attractive.
                We have highly trained professionals to complete such tasks.
                First of all, they notice the damaged or faded parts to which
                they will pay significant attention. They realize the work they
                need to do on your rug to make it new and glorious. After this,
                our experts gather the tools required to proceed and complete
                the process. If there is some wear and tear in the rug, we
                gather repair material. If the color is faded as well, our
                professionals also bring dyeing material. Putting the
                requirements of clients on priority. We repair your rug and
                restore its color suitably and make it vibrant for your living
                room or any other place where you want to keep it.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="black-sec-title margin-t-top">
              DYEBOLD OFFER CUSTOMIZATION
            </h3>
            <p>
              Apart from rug restoration and repair, people look for customized
              solutions as well. DyeBold meets this demand of the clients by
              coloring and designing damaged and faded carpets and rugs
              considering the requirements of the customers. We also provide
              them with the best results in this regard so that they can enjoy
              the color and pattern of rugs that they love. We offer a custom
              matching service so that you can match your rug to any desired
              color. It is an easy solution to make your rug suitable according
              to your needs. We also offer custom designing for your rug repair.
              In this way, you not only get to fix it but also innovate its look
              according to your desire.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
