import React from "react";
import "./SocialIcon.css";

function SocialIcon() {
  return (
    <>
      <div className="icon-bar">
        <a href="https://www.facebook.com/dyebold/" className="facebook">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.youtube.com/c/DyeBold/videos" className="youtube">
          <i className="fab fa-youtube"></i>
        </a>
        <a href="#" className="twitter">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="#" className="linkedin">
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
    </>
  );
}

export default SocialIcon;
