import React from "react";
import "./Academy.css";
import "../App.css";

export default function Academy() {
  return (
    <div id="academy">
      <div id="page-top-bg">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="page-title">
                <h2>DYEBOLD ACADEMY</h2>
                <p>
                  BASIC & ADVANCED TRAINING IN CARPET DYEING & RUG DYEING WITH
                  THE UNIQUE DYEBOLD FRAMEWORK & APP
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="dyebold-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h3>Go Here</h3>
            </div>
            <div className="col-md-4">
              <a
                href="https://dyebold.ecwid.com/Trainings-c109028504"
                className="btn btn-primary dyebold-red"
              >
                Training Sign Up!
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="https://dyebold.company.site/"
                className="btn btn-primary dyebold-red"
              >
                Purchase Dyes
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="gray-bg">
        <div className="space-gap"></div>
        <div className="container">
          <div className="row text-center">
            <h4 className="title-top">
              It helps you in your field and makes you eligible to satisfy your
              clients with your work. If you choose us to learn rug dyeing
              skills, you can take advantage of 10 years of experience and know
              all the mistakes you need to avoid while doing this task.
            </h4>
            <h3 className="text-dyebold">
              BASIC & ADVANCED CARPET DYEING FOR NYLON & POLYESTER RUG DYEING OF
              WOOL/SILK & VISCOSE
            </h3>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <img
            src="images/dyebold-academy-studio.jpg"
            className="img-fluid"
            alt="Responsive image"
          />
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="space-gap"></div>
          <div className="col-md-12">
            <div className="section-description">
              DyeBold Academy is an excellent carpet training institute that
              teaches and polishes your dyeing skills. No doubt the importance
              of carpet and rug dyeing is increasing day by day. However, there
              are only a few professionals available in the market who are
              experts in this skill. One of the prime reasons for that is the
              lack of skilled trainers.
              <br />
              <br />
              DyeBold Academy understands the needs of the market and provides
              easy learning solutions to interested students. We do not believe
              in sole theoretical knowledge when teaching a skill. We also focus
              on the hands-on practice of our students so that they can
              practically apply what they learn and start making money right
              away!
            </div>
          </div>
          <div className="space-gap"></div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="section-description">
              The institute from which you are learning matters a lot. DyeBold
              is a carpet training institute with numerous trained professionals
              ready to teach their outclass skills. Our facilitators are
              professional rug and carpet dyers. Most of them are also popular
              due to their premium skills.
            </div>
          </div>
          <div className="space-gap"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              src="images/Issa-Hoker-Dyebold-Founder1.jpg"
              className="img-fluid"
              alt="Responsive image"
            />
          </div>
          <div className="col-md-6">
            <p>
              Issa Hoker, <strong>the founder, and CEO of DyeBold</strong> is a
              famous dyeing expert in the carpet industry.
            </p>
            <p>
              “We not only train you for getting common and small dyeing
              projects but also develop your skill to handle bigger and
              profitable works without much discomfort. So, Learn carpet dyeing
              from us to add extraordinary skill to your CV”
            </p>

            <strong>
              Start Your Dyeing Learning Journey With The Top Carpet Training
              Institute.
            </strong>
          </div>
        </div>
      </div>

      <div className="gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6" id="award-img">
              <img
                src="images/our-carpet-training-academy.jpg"
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
            <div className="col-md-6">
              <div className="section-description">
                <h3>CARPET DYEING TRAINING</h3>
                Carpet dyeing is an artistic skill that is highly valuable in
                the market. DyeBold Academy understands the importance and
                market value of this skill. Therefore, we are offering carpet
                dyeing training programs to the people. Our training will make
                students eligible to do the dyeing projects and earn money. You
                can contact us if you are interested in learning this
                high-in-demand skill to earn a significant profit. <br />
                <br />
                We pay special attention to practical work so that you do not
                face any problems while dyeing carpets for your customers.
                Moreover, the methods we teach are highly easy to do and save
                your time, suitably. If you have learned carpet dyeing from
                someone else but did not find it helpful, come to us. Our modern
                techniques will make this skill easy to learn for you. You can
                also learn from us even if you have no idea about this field. We
                can build and polish your skill from scratch and make you an
                expert.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dyebold-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="section-description">
                <h3>RUG DYEING WORKSHOPS</h3>
                People often need rugs in their houses and offices. If you
                glorify them with suitable dyeing, they add extra charm to their
                place. DyeBold Academy is well-aware of the importance and
                market demand for rug dyeing. We can offer training programs and
                workshops to teach our students an efficient method of dyeing
                rugs. Our professional teachers not only teach you the dyeing
                methods but also strengthen your confidence by allowing you to
                do some practical dyeing. <br />
                <br />
                It helps you in your field and makes you eligible to satisfy
                your clients with your work. If you choose us to learn rug
                dyeing skills, you can take advantage of 10 years of experience
                and know all the mistakes you need to avoid while doing this
                task.
              </div>
            </div>

            <div className="col-md-6" id="issa-trainning">
              <img
                src="images/rug-restoration.jpg"
                className="img-fluid"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
