import React from "react";
import "./BestChoice.css";

function BestChoice() {
  return (
    <div id="BestChoice">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="padding">
              <h4 className="title-top">DYEBOLD IS YOUR BEST CHOICE </h4>
              <h3 className="text-best-choice">
                THE LEADING CHOICE FOR CARPET DYEING
              </h3>
            </div>
          </div>

          <div className="col-md-6">
            <img
              className="shadow top img-fluid"
              src="images/choice.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BestChoice;
