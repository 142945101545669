import React from "react";
import "./SideMatch.css";
import "../App.css";

export default function SideMatch() {
  return (
    <div id="sidematch">
      <div id="page-top-bg">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="page-title">
                <h2>CARPET SIDE MATCH</h2>
                <p>
                  TRAINED BY THE INDUSTRY LEADER IN COLOR RESTORATION SERVICES.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="margin-t-top">
              LOOKING TO RESOLVE YOUR CARPET SIDE MATCH PROBLEMS?
            </h3>
            <p>
              DyeBold is a highly specialized company always there to help you
              do a carpet side match. We work with dedication to achieve
              customer satisfaction and good response. Carpet side match is a
              problem faced by many people, as it is not noticed at the first
              sight. Two or more carpets with the same color and pattern may get
              mismatched sometimes. It can happen due to inappropriate color
              mixing or machine errors.DyeBold can be highly helpful for you in
              this matter. We have easy yet highly effective solutions to these
              problems. Our professionals understand the difference
              appropriately and dye one of the mismatched carpets or dye all of
              them with a new shade. In this way, their color as well as their
              pattern gets matched suitably and it becomes difficult to
              differentiate between them.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="row">
            <div className="col-md-6">
              <img
                src="images/carpet-folower-design.jpg"
                className="img-fluid img-border"
                alt="Responsive image"
              />
            </div>
            <div className="col-md-6">
              <img
                src="images/carpet-dyeing-side-match.jpg"
                className="img-fluid img-border"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="black-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="black-sec-title margin-t-top">
                HOW IS THE CARPET SIDE MATCH DONE?
              </h3>
              <p>
                Side matching of the carpet is not an easy task to perform, once
                the difference is established. However, DyeBold can perform this
                task without much discomfort. Our professionals adopt the most
                efficient way to complete this task. We know that a slight
                change may come again if carpet color blending is not done
                suitably. Correction of such errors is the task of our highly
                trained experts. Initially, analysis of the difference is highly
                important. We understand how much the color of a carpet is
                deviating from the original one. After this, the required dye is
                made through proper and careful mixing. In the end, we apply the
                new hue to the carpet and match the carpet colors. The chances
                of errors are rare in this matter, as we place the similar
                carpets side by side while blending their pattern and dye.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="space-gap"></div>
              <h3>WHY DYEBOLD FOR CARPET SIDE MATCH?</h3>
              <p>
                DyeBold is included in the best carpet dyeing companies
                available to serve you with carpet side match facilities. Hiring
                experienced service providers to find solutions to the problems
                and getting outstanding services is a matter of prime concern
                for the clients.
              </p>
              <p>
                We always put the requirements of our clients the top priority
                and offer excellent solutions to their problems. We understand
                that sometimes it is highly difficult to blend carpet colors or
                match carpet patterns. However, our experts are always available
                to resolve this issue and provide you with the best results. We
                never compromise on quality. We always give our best shot so
                that our clients never feel disappointed. We consider our work
                finished only if our clients get satisfied with it.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="images/crafted-a-new-desing-by-carpet-side-match.jpg"
                className="img-fluid img-border"
                alt="Responsive image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
