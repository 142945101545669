import React from "react";
import TopBar from "./components/TopBar";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer"; // Ensure Footer is imported correctly

import "./App.css";

// Import Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Academy from "./pages/Academy";
import Dyeing from "./pages/Dyeing";
import SideMatch from "./pages/SideMatch";
import RugRestoration from "./pages/RugRestoration";
import Gallery from "./pages/Gallery";

// Import Routes
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <TopBar />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/academy" element={<Academy />} />
        <Route path="/carpet-dyeing" element={<Dyeing />} />
        <Route path="/carpet-side-match" element={<SideMatch />} />
        <Route path="/specialty-rug-restoration" element={<RugRestoration />} />
        <Route path="/our-gallery" element={<Gallery />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
