import React from "react";
import "./Service.css";

function Service() {
  return (
    <div id="Service">
      <div className="container">
        <div className="row text-center">
          <h2>TOP-RATED FLORIDA CARPET DYEING SERVICE</h2>
          <h3 className="text-dyebold">4 REASONS TO CHOOSE US</h3>
        </div>
        <div className="space-gap"></div>
        <div className="row">
          <div className="col-md-6 text-justify">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <i
                  className="fa-solid fa-certificate circle-icon"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="col-sm-8 col-md-8 margin">
                <h4 className="mb-4">FULLY TRAINED TEAM</h4>
                <p>
                  DyeBold Has Pursued Training From The Best Carpet & Rug Dyeing
                  Experts In The Industry.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 text-justify">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <i className="fa-solid fa-laptop-file circle-icon"></i>
              </div>
              <div className="col-sm-8 col-md-8 margin">
                <h4 className="mb-4">EASY ONLINE BOOKINGS</h4>
                <p>
                  Our Online System Lets You Know When We’re Available And How
                  Much Your Job Will Cost.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="space-gap"></div>

        <div className="row">
          <div className="col-md-6 text-justify">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <i
                  className="fa-solid fa-people-group circle-icon"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="col-sm-8  col-md-8 margin">
                <h4 className="mb-4">FRIENDLY, RELIABLE TEAM</h4>
                <p>
                  Dyebold Values Service With A Smile And Aims To Make Your Day
                  With Our Customer Service.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 text-justify">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <i
                  className="fa-solid fa-globe circle-icon"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="col-sm-8 col-md-8 margin">
                <h4 className="mb-4">MODERN PRACTICES</h4>
                <p>
                  We Use Modern Equipment And Techniques To Restore The Hue Of
                  Your Carpet And Rugs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
