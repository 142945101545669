import React from "react";
import "./Gallery.css";
import "../App.css";

export default function Gallery() {
  return (
    <div id="gallery">
      <div id="page-top-bg">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="page-title">
                <h2>OUR GALLERY</h2>
                <p>
                  TRAINED BY THE INDUSTRY LEADER IN COLOR RESTORATION SERVICES.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="margin-t-top">OUR WORK - CARPETS & RUGS DYEING</h3>
            <p>
              Carpet and rug dyeing is a creative work that must be done
              artistically by experts. DyeBold believes in providing its clients
              with excellent work. Our professionals have dye carpets and rugs
              for various clients and satisfied them with our exclusive work
              quality. We have also provided amazing rug restoration and carpet
              side match services to our customers to fulfill their needs.
            </p>

            <p>
              Here is some of our high-quality and customer-satisfactory work
              that illustrates our professionalism and quality maintenance. You
              can observe the artistic designs we make on your carpets and rugs.
              You can also see some images showing how we lead a project with
              dedication and teamwork. Our attention to detail is also
              unavoidable as we are highly cautious in doing every step. You can
              also see our videos on numerous topics such as; “How to dye faded
              carpet & rugs?” to see our magnificent carpet and rug dyeing.
            </p>
          </div>
          <div className="space-gap"></div>
        </div>
      </div>

      <div className="dyebold-bg">
        <div className="container">
          <div className="row text-center">
            <h4 className="title-top">AT DYEBOLD WE ARE 100% DEDICATED</h4>
            <h3 className="text-dyebold">OUR VIDEO GALLERY</h3>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/XAuRuguXedo"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-4">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/F59iaCdqf5o"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-4">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/VE-fQNgoxlc"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="space-gap"></div>
          <div className="row">
            <div className="col-md-4">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/2osx6D1_SeQ"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-4">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/NYsPJUGhl8Y"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="col-md-4">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/URJTsojoIwE"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="space-gap"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
