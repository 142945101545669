import React from "react";
import { Link } from "react-router-dom";
import "./TopBar.css";

function TopBar() {
  return (
    <>
      <div id="TopBar" className="bg-warning text-white py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="col-md-2">
                <i className="fa fa-phone" aria-hidden="true"></i>
              </div>
              <div className="col-md-8">
                <div className="call-now">Call us now</div>
                <span className="number">+1 (813) 320 4000</span>
              </div>
            </div>
            <div className="col-md-6">
              <section className="social-media">
                <div className="d-flex justify-content-end">
                  <div className="social-icons">
                    <Link
                      className="social-icon-link facebook"
                      to="https://www.facebook.com/dyebold/"
                      target="_blank"
                      aria-label="Facebook"
                    >
                      <i className="fab fa-facebook-f" />
                    </Link>
                    <Link
                      className="social-icon-link youtube"
                      to="https://www.youtube.com/c/DyeBold/videos"
                      target="_blank"
                      aria-label="Youtube"
                    >
                      <i className="fab fa-youtube" />
                    </Link>
                    <Link
                      className="social-icon-link twitter"
                      to="/"
                      target="_blank"
                      aria-label="Twitter"
                    >
                      <i className="fab fa-twitter" />
                    </Link>
                    <Link
                      className="social-icon-link twitter"
                      to="/"
                      target="_blank"
                      aria-label="LinkedIn"
                    >
                      <i className="fab fa-linkedin" />
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopBar;
