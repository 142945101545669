import React from "react";
import "./Certifications.css";

function Certifications() {
  return (
    <div id="Certifications">
      <div className="overlay">
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <i aria-hidden="true" className="fas fa-trophy"></i>
            </div>
            <div className="col-sm-10 col-md-10">
              <h3 className="text-best-choice">OUR CERTIFICATIONS</h3>
              <h4 className="title-top">
                Trained by the Industry Leader in Color Restoration Services.{" "}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certifications;
